@tailwind base;
@tailwind components;
@tailwind utilities;

.leaflet-container {
    /*width: 100%;*/
    /*height: 100%;*/
    width: 300px;
    height: 300px;
}

.drawer {
    min-height: 100vh;
    height: auto;
}